import React, { useState, useEffect } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { useSyncWithFirebase } from "../utils/firebase";
// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import LottieAnimation from "../components/LottieAnimation";

// const EXAMPLE_EXAM_KEY = "Class10_2024_CBSE_English_Set1";

// Taken exam key because directly taking url is causing encoding decoding much bugs
const GyaanExamPage = () => {
  const [examKey, setExamKey] = useState(null);
  // const defaultLayout = defaultLayoutPlugin();
  const [examUrl, setExamUrl] = useState("");

  // Fetching from React Native App
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const examKey = params.get("examKey");
    if (examKey) {
      setExamKey(examKey);
    }

    // setExamKey(EXAMPLE_EXAM_KEY);
  }, []);

  // Syncing with firebase
  useSyncWithFirebase({ path: `exams/${examKey}/url`, setFunction: setExamUrl });

  return (
    <div style={containerStyles}>
      {examUrl && (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer
            fileUrl={examUrl}
            renderLoader={() => (
              <LottieAnimation name="loading" animationStyle={{ height: 200, width: 200 }} />
            )}
            // defaultScale={5} plugins={[defaultLayout]}
          />
        </Worker>
      )}
    </div>
  );
};

export default GyaanExamPage;

const containerStyles = {
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "white",
};
