import React, { useEffect, useRef, useMemo } from "react";
import Lottie from "lottie-react";

const LottieAnimation = ({
  name,
  animationStyle = {},
  loop = true,
  autoplay = true,
  speed = 1,
}) => {
  const animationRef = useRef(null);
  const animationData = useMemo(() => {
    try {
      return require(`../assets/animations/${name}.json`);
    } catch (error) {
      console.error(`Animation not found: ${name}.json`, error);
      return null;
    }
  }, [name]);

  useEffect(() => {
    if (animationRef.current) {
      animationRef.current.setSpeed(speed);
    }
  }, [speed]);

  if (!animationData) return null;

  return (
    <Lottie
      lottieRef={animationRef}
      loop={loop}
      autoplay={autoplay}
      style={animationStyle}
      animationData={animationData}
    />
  );
};

export default React.memo(LottieAnimation);
